<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in rules" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in legal" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in regulations" :key="index">
          <a :href="route.route" target="_blank">{{ route.name }}</a>
        </li>
      </ul>
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        rules: [
          {
            name       :  'House rules',
            route      :  'rules-page'
          },
          {
            name       :  'Casino rules',
            route      :  'rules-page'
          }
        ],
        legal: [
          {
            name       :  'sports rules',
            route      :  'rules-page'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          },
          {
            name       :  'Parlay calculator',
            route      :  'parlay-calculator'
          },
        ],
        regulations: [
          {
            name       :  'sportsbook agent admin',
            route      :  'https://agents.monarchsports.co/AgentSiteV2/'
          }
        ]
      }
    },
  };
</script>
