<template>
  <div class="hamburger-menu">
    <button class="header-menu__icon" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
      <span class="material-symbols-rounded">menu</span>
    </button>
    <div class="hamburger-menu__content" :class="{ 'is-active': isActive }">
      <button @click="isActive = !isActive" class="hamburger-menu__close-button" aria-label="Cerrar menú"><span class="material-symbols-rounded">close</span></button>
      <ul class="hamburger-menu-mobile__list">
        <li class="hamburger-menu__list-anchor" @click="isActive = !isActive">
          <router-link class="hamburger-menu__item" to="/">
            <span><img src="../assets/images/icons/icon_home.svg" alt="home"></span>
            <span>Home</span>
          </router-link>
        </li>
        <li class="hamburger-menu__list-anchor" @click="isActive = !isActive">
          <router-link class="hamburger-menu__item" to="/parlay-calculator">
            <span><img src="../assets/images/icons/icon_calculadora.svg" alt="parlay calculator"></span>
            <span>parlay calculator</span>
          </router-link>
        </li>
        <li class="hamburger-menu__list-anchor" @click="isActive = !isActive">
          <a class="hamburger-menu__item" href="https://agents.monarchsports.co/AgentSiteV2/" target="_blank">
            <span><img src="../assets/images/icons/icon_Admin.svg" alt="agent admin"></span>
            <span>agent admin</span>
          </a>
        </li>
        <li class="hamburger-menu__list-anchor" @click="isActive = !isActive">
          <router-link class="hamburger-menu__item" to="rules-page">
            <span><img src="../assets/images/icons/icon_Rules.svg" alt="house rules"></span>
            <span>house rules</span>
          </router-link>
        </li>
        <li class="hamburger-menu__list-anchor" @click="isActive = !isActive">
          <router-link class="hamburger-menu__item" to="rules-page">
            <span><img src="../assets/images/icons/icon_Sports.svg" alt="sports rules"></span>
            <span>sports rules</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      isActive: false,
    };
  },
};
</script>
