<template>
  <button @click="scrollToTop" id="scrollToTopButton" class="scroll-to-top-button hidden">
    <span class="material-symbols-rounded">arrow_upward</span>
  </button>
</template>

<script>
  export default {
    name: "ButtonScroll",

    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
      handleScroll() {
        const scrollToTopButton = document.getElementById("scrollToTopButton");
        if (window.scrollY > 300) {
          scrollToTopButton.classList.add("visible");
          scrollToTopButton.classList.remove("hidden");
        } else {
          scrollToTopButton.classList.add("hidden");
          scrollToTopButton.classList.remove("visible");
        }
      }
    },

    mounted() {
      window.addEventListener("scroll", this.handleScroll);
    },

    beforeUnmount() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  }
</script>
