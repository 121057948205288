<template>
  <header-component/>
  <router-view/>
  <footer-component/>
  <bottomNavComponent/>
  <ButtonScroll/>
</template>

<script>
import headerComponent from "@/components/header.vue"
import FooterComponent from "@/components/footer.vue"
import bottomNavComponent from "@/components/bottomNav.vue"
import ButtonScroll from "@/components/button-scroll.vue"

export default {
  name: 'App',
  components: {
    headerComponent,
    FooterComponent,
    bottomNavComponent,
    ButtonScroll
  }
}
</script>
