<template>
  <nav class="bottom-nav">
    <div class="bottom-nav__container">
      <ul class="bottom-nav__menu">
        <menu-mobile/>
        <!-- <button class="hamburger-menu__button" @click="isActive = !isActive" :class="{ 'is-active': isActive }">
          <img src="@/assets/images/icons/Hamburguer2.svg" alt="hamburguer">
        </button> -->
        <div class="bottom-nav__info">
          <ul class="bottom-nav__info--list">
            <li class="bottom-nav__info--list-item">
              <router-link to="sportsbook">
                <!-- <img class="bottom-nav__icon" src="@/assets/images/icons/sports.svg" alt="Sports-icons" /> -->
                Sports
              </router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="casino">
                <!-- <img class="bottom-nav__icon" src="@/assets/images/icons/casino.svg" alt="Casino-icons" /> -->
                Casino
              </router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="racebook">
                <!-- <img class="bottom-nav__icon" src="@/assets/images/icons/horses.svg" alt="Racebook-icons" /> -->
                RACEBOOK
              </router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="live-betting">
                <!-- <img class="bottom-nav__icon" src="@/assets/images/icons/live.svg" alt="Racebook-icons" /> -->
                Livebetting
              </router-link>
            </li>
            <li class="bottom-nav__info--list-item">
              <router-link to="props-Builder">
                <!-- <img class="header-content__icon " src="@/assets/images/icons/props.svg" alt="Racebook-icons"> -->
                Props Builder
              </router-link>
            </li>
          </ul>
        </div>
      </ul>
    </div>
  </nav>
</template>

<script>
import MenuMobile from "@/components/menu-mobile.vue"

export default {
  name: "HeaderComponent",
  components: {
    MenuMobile,
  },
  setup() { },
};
</script>
