<template>
  <header class="header">
    <div class="header-content max-container">
      <div class="header-content__nav">
        <menu-component/>
      </div>
      <div class="header-content__nav--img">
        <a href="/">
          <img class="header-content__img" src="../assets/images/monarch-logo.png" alt="wager connection" />
        </a>
      </div>
      <div class="header-content__login">
        <!-- Mobile form -->
        <form class="login__form form__mobile" method="post" :action="`//mobile.${backendUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
          
          <!-- <label class="login__form--label" for="account">Username</label> -->
          <input class="login__form--input" type="text" name="account" id="account" placeholder="USERNAME">
          
          <label class="login__form--label" for="password">Password</label>
          <input class="login__form--input" type="password" name="password" id="password" placeholder="PASSWORD">
  
          <div class="login__btns-login">
            <button class="login__button login__button--primary" type="submit">Login</button>
          </div>
        </form>
  
        <!-- Desktop form -->
        <form class="login__form form__desktop" method="post" :action="`//wager.${backendUrl}/DefaultLogin.aspx`">
          <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`"/>
          <input type="hidden" name="errorURL" :value="`//www.${backendUrl}/?login-error`"/> 
          
          <label class="login__form--label" for="account">Username</label>
          <input class="login__form--input" type="text" name="account" id="account" placeholder="USERNAME">
          
          <label class="login__form--label" for="password">Password</label>
          <input class="login__form--input" type="password" name="password" id="password" placeholder="PASSWORD">
          
          <div class="login__btns-login">
            <button class="login__button login__button--primary" type="submit">Login</button>
          </div>
        </form>

        <!-- <router-link to="forgot-password" class="header-content__login--forgot">Problems loggin in?</router-link> -->
      </div>
    </div>
  </header>
</template>

<script>
  import MenuComponent from '@/components/menu.vue'

  export default { 
    name: "HeaderComponent",
    components: {
      MenuComponent
    },
    setup() {
      const DGS_SITEID = 252;
      const backendUrl = "monarchsports.co";

      return {
        DGS_SITEID,
        backendUrl,
      }
    },

    methods: {
      openModal() {
        this.$refs.modal.openModal();
      }
    },
  };
</script>
